<template>
    <b-modal :id="uniqueId" :ref="uniqueId" @hidden="handleClose">
        <template slot="modal-header" slot-scope="{ close }">
            <i class="btn btn-link my-auto" @click="close()"><font-awesome-icon :icon="['fal', 'long-arrow-left']" /></i>
            <h2 class="mx-auto my-auto">{{ $t('syndication.activer_syndication_modal_titre') }}</h2>
        </template>
        <template slot="default">
            <b-form>
				<div class="form-group">
                    <b-form-radio 
						:disabled="clone_prev_syndic"
                        v-for="(type, idx) in syndic_types"
                        :key="'syndic_type_'+idx"
                        v-model="syndic.type"
                        :name="type.syndictype_code"
                        :value="type.syndictype_id"
                        class="custom-control-inline"
                    >
                        {{ $t(type.syndictype_labeltrad) }}
                    </b-form-radio>
                </div>
                
                <div v-if="askForEntity" class="form-group">
                    <label>{{ $t('global.syndicat') }}</label>
                    <e-select
						:disabled="clone_prev_syndic"
                        v-model="syndic.tier"
                        track-by="tiers_id"
                        label="tiers_rs"
                        :placeholder="$t('syndication.selectionner_tier_syndicat')"
                        :selectedLabel="$t('global.selected_label')"
                        :options="tiers"
                        :searchable="true"
                        :allow-empty="false"
                        :loading="loading"
                        :show-labels="true"
                    />
                </div>

                <div class="form-group">
                    <label>{{ $t('syndication.nom_syndic') }}</label>
                    <b-input v-model="syndic.label"></b-input>
                </div>

                <div class="form-group">
                    <label>{{ $t('gynecologie.saison') }}</label>
                    <e-select
						:disabled="clone_prev_syndic"
                        v-model="syndic.season"
                        id="season_id"
                        track-by="season_id"
                        label="season_label"
                        :placeholder="$t('gynecologie.selectionner_saison')"
                        :selectedLabel="$t('global.selected_label')"
                        :options="seasons"
                        :searchable="true"
                        :allow-empty="false"
                        :loading="loading"
                        :show-labels="true"
                        :multiple="true"
                    />
                </div>
                <div class="form-group">
                    <label>{{ $t('syndication.saillies_etalonnier') }}</label>
                    <b-input :disabled="clone_prev_syndic" v-model="syndic.tier_saillies"></b-input>
                </div>
                <div class="form-group">
                    <label>{{ $t('syndication.saillies_par_part') }}</label>
                    <b-input :disabled="clone_prev_syndic" v-model="syndic.part_saillies" type="number" min="1"></b-input>
                </div>
            </b-form>

			<div v-if="canClonePrevSyndic">
				<div class="mt-2 mb-2 separator">{{ $t('global.ou') }}</div>
					<b-checkbox
						v-model="clone_prev_syndic"
						class="mt-1"
					>
						{{ $t('syndication.clone_syndic', [this.prev_syndic.syndic_season.season_label]) }}
					</b-checkbox>
			</div>
        </template>

        <!-- <template slot="modal-footer" slot-scope="{ ok }" class="justify-content-center"> -->
        <template slot="modal-footer" class="justify-content-center">
            <b-button variant="primary" @click="checkForm()" :disabled="!formComplete">
                <font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse />
                {{ $t('global.activer') }}
            </b-button>
        </template>
    </b-modal>
</template>


<script type="text/javascript">
    import Gynecologie from "@/mixins/Gynecologie.js"
    import Syndic from "@/mixins/Syndic.js"
	import Tiers from "@/mixins/Tiers.js"
	import _cloneDeep from 'lodash/cloneDeep'

	export default {
        name: "CreateSyndicModal",
        mixins: [Gynecologie, Syndic, Tiers],
        props: {
			horse_id: { type: Number, default: 0},
            submitForm: { type: Function, default: () => false }
        },
        data() {
            return {
                tiers: [],
                seasons: [],
                syndic_types: [],
                loading: true,
				processing: false,
				clone_prev_syndic: false,
				syndic_id: 0,
				syndic: null,
				prev_syndic: null,
                default_syndic: {
                    tier: null,
                    season: null,
                    type: 1,
                    tier_saillies: 0,
                    part_saillies: 1,
                }
            }
        },
        computed: {
			isEditing() {
				return this.syndic_id > 0
			},
            uniqueId() {
                return 'modal-create-'+this._uid
			},
			askForEntity() {
				return this.syndic.type != 1 // autre qu'indivision
			},
			canClonePrevSyndic() {
				return !!this.prev_syndic
			},
            formComplete() {
                return this.askForEntity ? this.syndic.tier !== null : true
                    && this.syndic.season !== null
                    && this.syndic.part_saillies > 0
            }
		},
		created() {
			this.init_modal()
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
				this.processing = false
                let tab_promises = []

                tab_promises.push(new Promise((rsv, _) => {
                    this.loadTiersEntity()
                        .then(res => this.tiers = res)
                        .finally(rsv())
                }))
                tab_promises.push(new Promise((rsv, _) => {
                    this.loadSyndicTypes()
                        .then(res => this.syndic_types = res)
                        .finally(rsv())
                }))
                tab_promises.push(new Promise((rsv, _) => {
                    this.loadSeasons()
                        .then(res => {
                            this.seasons = res
                            this.syndic.season = res.find(season => season.season_default === true)
                        })
                        .finally(rsv())
                }))
				// tab_promises.push(new Promise((rsv, _) => {
    //                 this.loadHorsePreviousSyndic(this.horse_id)
    //                     .then(res => this.prev_syndic = res)
    //                     .finally(rsv())
    //             }))

                await Promise.all(tab_promises)
                this.loading = false
			},

			async init_modal() {
				this.syndic = _cloneDeep(this.default_syndic)
				
				if(this.isEditing) {
					await this.loadSyndic(this.syndic_id)
						.then(res => this.fillSyndicProps(res))
				}
			},
			
			fillSyndicProps(res) {
                this.syndic.label = res.syndic_label
				this.syndic.type = res.syndic_type.syndictype_id
				this.syndic.tier = this.askForEntity ? this.tiers.find(t => t.tiers_id === res.syndic_tiersyndicat.tiers_id) : null
				this.syndic.season = res.syndic_season.map(r => r.syndicseason_season)
				this.syndic.tier_saillies = res.syndic_saillies_etalonnier
				this.syndic.part_saillies = res.syndic_saillies_par_part
			},

            checkForm() {
				this.processing = true
                if(this.formComplete) {
                    let season = {}
                    if(this.syndic.season.season_id) {
                        season = [this.syndic.season.season_id]
                    }
                    else {
                        season = this.syndic.season.map(s => s.season_id)
                    }
                    this.submitForm({
                        label: this.syndic.label,
                        tier_id: this.askForEntity ? this.syndic.tier.tiers_id : null,
                        seasons: season,
                        type: this.syndic.type,
                        tier_saillies: this.syndic.tier_saillies,
                        part_saillies: this.syndic.part_saillies
                    })
                }
            },

            async openModal(syndic_id=0) {
				this.syndic_id = syndic_id

				if(syndic_id > 0) {
					this.loading = true

					this.init_modal()
						.finally(() => {
							this.loading = false
						})
				}

                this.$bvModal.show(this.uniqueId)
            },

            closeModal() {
                this.processing = false
                this.$bvModal.hide(this.uniqueId)
            },

            handleClose() {
				return false
            }
        }
	}
</script>
